<template>
  <v-main class="login">
    <div class="text-center">
      <v-btn rounded class="maincolor-bg default_button"
             @click.stop="callApi()">退会</v-btn>
    </div>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      init: this.storageGet('*'),
    };
  },
  mounted () {
  },
  methods: {
    async callApi() {
      try {
        //loadingを表示
        this.loading = true;

        // 退会
        const quit_req = {
          'quit_reason': 'test quit_reason',
        };
        await this.callPfApiFromEcMock('/user/' + this.init.device_uid + '/ecquit' , quit_req);

        // // TODO ユーザとトークンをクリアしないといけない。トップページで。
        // this.clearUserAndToken();
        await this.requestToNativeToShowTopPage();
        this.loading = false;
      } catch(e) {
        console.log(e);
        this.loading = false
        this.callDialog('エラー', e.message, 3);
      }
    }
  }
};
</script>

<style scoped>
.fontsize11> >>label {
  font-size: 11px;
}

a {
  color: #333333;
}

</style>
